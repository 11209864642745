// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick_container {
  padding: 0px 30px 25px 30px;
}

.partner_slider_container {
  display: flex !important;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/InfinitSlider/infinitSlider.scss"],"names":[],"mappings":"AACA;EACE,2BAAA;AAAF;;AAGA;EACE,wBAAA;EACA,8BAAA;AAAF","sourcesContent":["\n.slick_container {\n  padding: 0px 30px 25px 30px;\n}\n\n.partner_slider_container {\n  display: flex !important;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
