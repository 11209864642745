// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav_container {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 12;
  display: flex;
  margin: auto;
  max-width: 1440px;
}
@media (max-width: 499px) {
  .nav_container {
    top: 35px;
  }
}
.nav_container .nav {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-right: 5px;
}
.nav_container .nav .open-menu {
  display: flex;
  border: 1px solid;
  background-color: white;
  padding: 10px;
  border-radius: 60px;
  cursor: pointer;
}
@media (max-width: 499px) {
  .nav_container .nav .open-menu {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav_container .nav .open-menu svg {
    width: 100%;
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/HorizontalNavbar/horizontalNavbar.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,iBAAA;AADF;ACPE;EDDF;IAWI,SAAA;EACF;AACF;AAAE;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;AAEJ;AADI;EACE,aAAA;EACA,iBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAGN;AC5BE;EDmBE;IAQI,WAAA;IACA,YAAA;IACA,aAAA;IACA,uBAAA;IACA,mBAAA;EAKN;EAJM;IACE,WAAA;IACA,YAAA;EAMR;AACF","sourcesContent":["@import \"theme/theme.scss\";\n\n.nav_container {\n  height: 80px;\n  width: 100%;\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  z-index: 12;\n  display: flex;\n  margin: auto;\n  max-width: 1440px;\n  @include mobile {\n    top: 35px;\n  }\n  .nav {\n    display: flex;\n    flex: 1;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 20px;\n    margin-right: 5px;\n    .open-menu {\n      display: flex;\n      border: 1px solid;\n      background-color: white;\n      padding: 10px;\n      border-radius: 60px;\n      cursor: pointer;\n      @include mobile {\n        width: 65px;\n        height: 65px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        svg {\n          width: 100%;\n          height: 100%;\n        }\n      }\n    }\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
