// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Fredoka One";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredokaone/v8/k3kUo8kEI-tA1RRcTZGmTlHGCaen8wf-.woff2) format("woff2");
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--open-sans);
  font-weight: 700;
}

:root {
  --grey: #666;
  --grey-light: #999;
  --grey-dark: #333;
  --blue-green: #60eed2;
  --blue-green-hover: #5ec7b2;
  --red-kirby: #FF6580;
  --red-kirby-hover: #ab3e51;
  --orange: #ffcd38;
  --orange-hover: #ff9100;
  --bg-grey: #F9FBFD;
  --open-sans: "Work Sans";
  --fredoka: "Fredoka One", cursive;
  --white-mode: #ffffff;
  --dark-mode: #000000;
}

#root {
  height: 100%;
}

main {
  background-color: #333;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  height: 100vh;
  margin: 0;
  background-color: #23272A;
  font-weight: 500;
  font-family: "Work Sans" !important;
  color: #23272A;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Work Sans", monospace;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AACA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,0GAAA;AAAF;AAGA;EACE,mCAAA;EACA,kCAAA;EACA,6BAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,2BAAA;EACA,oBAAA;EACA,0BAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,wBAAA;EACA,iCAAA;EAEA,qBAAA;EACA,oBAAA;AAFF;;AAMA;EACE,YAAA;AAHF;;AAOA;EACE,sBAAA;AAJF;;AAQA;EACE,qBAAA;EACA,cAAA;AALF;;AAQA;EACE,aAAA;EACA,SAAA;EACA,yBAAA;EACA,gBAAA;EACA,mCAAA;EACA,cAAA;EACA,gBAAA;EACA,mCAAA;EACA,kCAAA;AALF;;AAQA;EACE,mCAAA;AALF","sourcesContent":["\n@font-face {\n  font-family: 'Fredoka One';\n  font-style: normal;\n  font-weight: 400;\n  font-display: swap;\n  src: url(https://fonts.gstatic.com/s/fredokaone/v8/k3kUo8kEI-tA1RRcTZGmTlHGCaen8wf-.woff2) format('woff2');\n};\n\n* {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-family: var(--open-sans);\n  font-weight: 700;\n}\n\n:root {\n  --grey: #666;\n  --grey-light: #999;\n  --grey-dark: #333;\n  --blue-green: #60eed2;\n  --blue-green-hover: #5ec7b2;\n  --red-kirby: #FF6580;\n  --red-kirby-hover: #ab3e51;\n  --orange : #ffcd38;\n  --orange-hover : #ff9100;\n  --bg-grey: #F9FBFD;\n  --open-sans: 'Work Sans';\n  --fredoka: 'Fredoka One', cursive;\n  \n  --white-mode: #ffffff;\n  --dark-mode: #000000;\n \n}\n\n#root {\n  height: 100%;\n  // margin: auto;\n}\n\nmain {\n  background-color: #333;\n\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\nbody {\n  height: 100vh;\n  margin: 0;\n  background-color: #23272A;\n  font-weight: 500;\n  font-family: 'Work Sans' !important;\n  color: #23272A;\n  line-height: 1.4;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Work Sans',\n    monospace;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
