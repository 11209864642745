// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sections {
  overscroll-behavior: none;
}`, "",{"version":3,"sources":["webpack://./src/views/vitrineContainer.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF","sourcesContent":["@import 'theme/theme.scss';\n\n.sections {\n  overscroll-behavior: none;\n}\n\n// @include mobile {\n//   .sections {\n//     top: 965px !important;\n//   }\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
