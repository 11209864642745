// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.introduction_usecase .introduction_usecase_container {
  margin: auto;
  max-width: 1440px;
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content {
  background-color: #23272a;
  padding: 100px 60px;
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content h1 {
  color: white;
  margin: 0;
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs {
  width: 90%;
  margin: 0px 0 45px 0;
  color: white;
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs .paraph2 {
  font-size: 32px;
  font-weight: 600;
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs .paraph1 {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 499px) {
  .introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs {
    width: 100%;
  }
  .introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs .paraph1, .introduction_usecase .introduction_usecase_container .introduction_usecase_content .paragraphs .paraph2 {
    font-size: 28px;
  }
}
.introduction_usecase .introduction_usecase_container .introduction_usecase_content .introduction_activity_sector {
  font-size: 24px;
  font-weight: 700;
  color: white;
}
@media (max-width: 499px) {
  .introduction_usecase .introduction_usecase_container {
    padding: 0 70px;
  }
}
.introduction_usecase .introduction_usecase_logos {
  display: flex;
  align-items: center;
  width: 400px;
  padding: 40px 60px;
}
.introduction_usecase .introduction_usecase_logos .ffylogo {
  width: 124px;
  height: 102px;
}
.introduction_usecase .introduction_usecase_logos .usecase-logo {
  height: 102px;
}
.introduction_usecase .introduction_usecase_logos .usecase-logo img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/views/useCases/home/components/IntroductionFFY/introductionffy.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,iBAAA;AAFJ;AAGI;EACE,yBAAA;EACA,mBAAA;AADN;AAEM;EACE,YAAA;EACA,SAAA;AAAR;AAEM;EACE,UAAA;EACA,oBAAA;EACA,YAAA;AAAR;AAEQ;EACE,eAAA;EACA,gBAAA;AAAV;AAEQ;EACE,eAAA;EACA,gBAAA;AAAV;ACrBE;EDUI;IAcI,WAAA;EACR;EAAQ;IACE,eAAA;EAEV;AACF;AACM;EACE,eAAA;EACA,gBAAA;EACA,YAAA;AACR;AClCE;EDAA;IAqCI,eAAA;EACJ;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAAI;EACE,YAAA;EACA,aAAA;AAEN;AAAI;EACE,aAAA;AAEN;AADM;EACE,WAAA;AAGR","sourcesContent":["@import \"theme/theme.scss\";\n\n.introduction_usecase {\n  .introduction_usecase_container {\n    margin: auto;\n    max-width: 1440px;\n    .introduction_usecase_content {\n      background-color: #23272a;\n      padding: 100px 60px;\n      h1 {\n        color: white;\n        margin: 0;\n      }\n      .paragraphs {\n        width: 90%;\n        margin: 0px 0 45px 0;\n        color: white;\n       \n        .paraph2 {\n          font-size: 32px;\n          font-weight: 600;\n        }\n        .paraph1 {\n          font-size: 24px;\n          font-weight: 600;\n        }\n        @include mobile {\n          width: 100%;\n          .paraph1, .paraph2 {\n            font-size: 28px;\n          }\n        }\n      }\n      .introduction_activity_sector {\n        font-size: 24px;\n        font-weight: 700;\n        color: white;\n      }\n    }\n    @include mobile {\n      padding: 0 70px;\n    }\n  }\n  .introduction_usecase_logos {\n    display: flex;\n    align-items: center;\n    width: 400px;\n    padding: 40px 60px;\n    .ffylogo {\n      width: 124px;\n      height: 102px;\n    }\n    .usecase-logo {\n      height: 102px;\n      img {\n        width: 100%;\n      }\n    }\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
