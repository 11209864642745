// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usecase_double_image_container {
  background-color: white;
  padding: 100px 0;
}
.usecase_double_image_container .usecase_double_image_content {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.usecase_double_image_container .usecase_double_image_src1 {
  width: 700px;
}
.usecase_double_image_container .usecase_double_image_src2 {
  width: 500px;
}
.usecase_double_image_container .usecase_double_image_src1 img, .usecase_double_image_container .usecase_double_image_src2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 499px) {
  .usecase_double_image_container {
    padding: 100px 65px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/useCases/home/components/ImageBloc/imageBloc.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;EACA,gBAAA;AAAF;AACE;EACE,aAAA;EACA,SAAA;EACA,8BAAA;AACJ;AACE;EACE,YAAA;AACJ;AACE;EACE,YAAA;AACJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAAN;AChBE;EDFF;IAsBI,mBAAA;EAAF;AACF","sourcesContent":["@import \"theme/theme.scss\";\n.usecase_double_image_container {\n  background-color: white;\n  padding: 100px 0;\n  .usecase_double_image_content {\n    display: flex;\n    gap: 20px;\n    justify-content: space-between;\n  }\n  .usecase_double_image_src1 {\n    width: 700px;\n  }\n  .usecase_double_image_src2 {\n    width: 500px;\n  }\n  .usecase_double_image_src1, .usecase_double_image_src2 {\n    img {\n      width: 100%;\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n  @include mobile {\n    padding: 100px 65px;\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
