// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ffy_introduction .introduction_container {
  margin: auto;
  max-width: 1440px;
}
@media (max-width: 499px) {
  #ffy_introduction .introduction_container .introduction_logo {
    padding-left: 65px;
  }
  #ffy_introduction .introduction_container .introduction_logo img {
    width: 230px;
    height: 175px;
  }
}
#ffy_introduction .introduction_container .introduction_content {
  background-color: #23272a;
  padding: 100px 120px;
}
#ffy_introduction .introduction_container .introduction_content .introduction_paragraphs {
  color: white;
  width: 90%;
  margin: 45px 0;
}
#ffy_introduction .introduction_container .introduction_content .introduction_paragraphs .paraph1 {
  font-size: 32px;
  font-weight: 600;
}
#ffy_introduction .introduction_container .introduction_content .introduction_paragraphs .paraph2 {
  font-size: 17px;
  font-weight: 600;
}
@media (max-width: 499px) {
  #ffy_introduction .introduction_container .introduction_content .introduction_paragraphs {
    padding: 0 65px;
  }
  #ffy_introduction .introduction_container .introduction_content .introduction_paragraphs .paraph1,
  #ffy_introduction .introduction_container .introduction_content .introduction_paragraphs .paraph2 {
    font-size: 28px;
  }
}
#ffy_introduction .introduction_container .introduction_content .main_title {
  text-align: left;
}
@media (max-width: 499px) {
  #ffy_introduction .introduction_container .introduction_content .btn {
    text-align: -webkit-center;
    padding: 70px 0 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/IntroductionFFY/introductionffy.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,iBAAA;AAFJ;ACAE;EDGE;IAEI,kBAAA;EADN;EAEM;IACE,YAAA;IACA,aAAA;EAAR;AACF;AAGI;EACE,yBAAA;EACA,oBAAA;AADN;AAEM;EACE,YAAA;EACA,UAAA;EACA,cAAA;AAAR;AACQ;EACE,eAAA;EACA,gBAAA;AACV;AACQ;EACE,eAAA;EACA,gBAAA;AACV;AC1BE;EDeI;IAaI,eAAA;EAER;EADQ;;IAEE,eAAA;EAGV;AACF;AAAM;EACE,gBAAA;AAER;ACtCE;EDwCM;IACE,0BAAA;IACA,mBAAA;EACR;AACF","sourcesContent":["@import \"theme/theme.scss\";\n\n#ffy_introduction {\n  .introduction_container {\n    margin: auto;\n    max-width: 1440px;\n    .introduction_logo {\n      @include mobile {\n        padding-left: 65px;\n        img {\n          width: 230px;\n          height: 175px;\n        }\n      }\n    }\n    .introduction_content {\n      background-color: #23272a;\n      padding: 100px 120px;\n      .introduction_paragraphs {\n        color: white;\n        width: 90%;\n        margin: 45px 0;\n        .paraph1 {\n          font-size: 32px;\n          font-weight: 600;\n        }\n        .paraph2 {\n          font-size: 17px;\n          font-weight: 600;\n        }\n        @include mobile {\n          padding: 0 65px;\n          .paraph1,\n          .paraph2 {\n            font-size: 28px;\n          }\n        }\n      }\n      .main_title {\n        text-align: left;\n      }\n      @include mobile {\n        // padding: 120px 30px !important;\n        .btn {\n          text-align: -webkit-center;\n          padding: 70px 0 0 0;\n        }\n      }\n    }\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
