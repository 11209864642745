// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_title {
  width: 90%;
}
.content_title h1, .content_title h2, .content_title h3, .content_title h4, .content_title h5, .content_title h6 {
  margin: 0;
}
@media (max-width: 499px) {
  .content_title {
    width: 90%;
    margin: auto;
    text-align: -webkit-center;
  }
  .content_title .sub_title {
    justify-content: left;
  }
}

.main_title {
  font-size: 64px;
  font-weight: 700;
  color: white;
  line-height: 1;
}
@media (max-width: 499px) {
  .main_title {
    font-size: 55px !important;
  }
}

.sub_title {
  color: white;
  display: flex;
  align-items: center;
  font-size: 48px;
}
.sub_title h1, .sub_title h2, .sub_title h3, .sub_title h4, .sub_title h5, .sub_title h6 {
  font-size: inherit;
  font-weight: 600;
}

@media (max-width: 499px) {
  .content_title2 {
    width: 90%;
  }
  .content_title2 .main_title {
    font-size: 35px;
    padding: 0 65px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DivideTitle/divideTitle.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;AADF;AAEE;EACE,SAAA;AAAJ;ACFE;EDDF;IAMI,UAAA;IACA,YAAA;IACA,0BAAA;EACF;EAAE;IACE,qBAAA;EAEJ;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;AAEF;ACnBE;EDaF;IAMI,0BAAA;EAIF;AACF;;AAFA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAKF;AAJE;EACE,kBAAA;EACA,gBAAA;AAMJ;;ACnCE;EDiCF;IAEI,UAAA;EAKF;EAJE;IACE,eAAA;IACA,eAAA;EAMJ;AACF","sourcesContent":["@import 'theme/theme.scss';\n\n.content_title {\n  width: 90%;\n  h1, h2, h3,h4,h5,h6 {\n    margin: 0;\n  }\n  @include mobile {\n    width: 90%;\n    margin: auto;\n    text-align: -webkit-center;\n    .sub_title {\n      justify-content: left;\n    }\n  }\n}\n.main_title {\n  font-size: 64px;\n  font-weight: 700;\n  color: white;\n  line-height: 1;\n  @include mobile {\n    font-size: 55px !important;\n  }\n}\n.sub_title {\n  color: white;\n  display: flex;\n  align-items: center;\n  font-size: 48px;\n  h1, h2, h3,h4,h5,h6 {\n    font-size: inherit;\n    font-weight: 600;\n  }\n}\n\n.content_title2  {\n  @include mobile {\n    width: 90%;  \n    .main_title {\n      font-size: 35px;  \n      padding: 0 65px;\n    }\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
