// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usecase_video_player {
  background-color: white;
  width: 100%;
}
.usecase_video_player .usecase_video_player_content {
  width: 100%;
  height: 100%;
}
.usecase_video_player .usecase_video_player_content :global(iframe),
.usecase_video_player .usecase_video_player_content .thumbnail {
  border: none;
  width: 100%;
  height: 100%;
}
.usecase_video_player .usecase_video_player_content iframe {
  width: 100%;
  height: 100%;
}

.thumbnail {
  height: 554px;
  width: 100%;
  position: relative;
}
.thumbnailImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(60%);
}
.thumbnail .playIcon svg,
.thumbnail .playIconMain svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.thumbnail .playIcon svg {
  height: 80px;
  width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/views/useCases/home/components/VideoPlayer/videoPlayer.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,WAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;AAEJ;AADI;;EAGE,YAAA;EACA,WAAA;EACA,YAAA;AAEN;AAAI;EACE,WAAA;EACA,YAAA;AAEN;;AAGA;EACE,aAAA;EACA,WAAA;EACA,kBAAA;AAAF;AACE;EACE,YAAA;EACA,WAAA;EAEA,iBAAA;EACA,uBAAA;AAAJ;AAEE;;EAEE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;AAAJ;AAEE;EACE,YAAA;EACA,WAAA;AAAJ","sourcesContent":[".usecase_video_player {\n  background-color: white;\n  width: 100%;\n  .usecase_video_player_content {\n    width: 100%;\n    height: 100%;\n    :global(iframe),\n    .thumbnail {\n      // border-radius: 20px;\n      border: none;\n      width: 100%;\n      height: 100%;\n    }\n    iframe {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n\n.thumbnail {\n  height: 554px;\n  width: 100%;\n  position: relative;\n  &Img {\n    height: 100%;\n    width: 100%;\n    // border-radius: 20px;\n    object-fit: cover;\n    filter: brightness(60%);\n  }\n  .playIcon svg,\n  .playIconMain svg {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    color: #fff;\n  }\n  .playIcon svg {\n    height: 80px;\n    width: 80px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
