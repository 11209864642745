// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_container {
  padding: 85px 90px 50px 80px;
  display: flex;
  justify-content: space-between;
}
.footer_container .column_footer {
  display: flex;
  flex-direction: column;
}
.footer_container .column_footer .title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 25px;
}
.footer_container .column_footer .text {
  font-size: 16;
  padding: 5px;
  font-weight: 600;
}
@media (max-width: 499px) {
  .footer_container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer_container .column_footer {
    padding-bottom: 35px;
    text-align: center;
  }
  .footer_container .column_footer .title {
    font-size: 30px;
    padding-bottom: 5px;
  }
  .footer_container .column_footer .text {
    font-size: 25px;
  }
}

.footer_rights {
  padding: 33px 90px 50px 90px;
  display: flex;
  justify-content: space-between;
}
.footer_rights .ffy_conditions {
  display: flex;
  justify-content: space-between;
  width: 70%;
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 499px) {
  .footer_rights {
    font-size: 30px;
    align-items: center;
    flex-direction: column;
  }
  .footer_rights .ffy_conditions {
    padding: 20px 0;
    font-size: 30px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,aAAA;EACA,8BAAA;AADF;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;AACN;AACI;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AACN;ACfE;EDDF;IAmBI,sBAAA;IACA,uBAAA;IACA,mBAAA;EACF;EAAE;IACE,oBAAA;IACA,kBAAA;EAEJ;EADI;IACE,eAAA;IACA,mBAAA;EAGN;EADI;IACE,eAAA;EAGN;AACF;;AAEA;EACE,4BAAA;EACA,aAAA;EACA,8BAAA;AACF;AAAE;EACE,aAAA;EACA,8BAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AC9CE;EDmCF;IAYI,eAAA;IACA,mBAAA;IACA,sBAAA;EAGF;EAFE;IACE,eAAA;IACA,eAAA;IACA,WAAA;EAIJ;AACF","sourcesContent":["@import \"theme/theme.scss\";\n\n.footer_container {\n  padding: 85px 90px 50px 80px;\n  display: flex;\n  justify-content: space-between;\n  .column_footer {\n    display: flex;\n    flex-direction: column;\n    .title {\n      font-size: 20px;\n      font-weight: 700;\n      padding-bottom: 25px;\n    }\n    .text {\n      font-size: 16;\n      padding: 5px;\n      font-weight: 600;\n    }\n  }\n  @include mobile {\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    .column_footer {\n      padding-bottom: 35px;\n      text-align: center;\n      .title {\n        font-size: 30px;\n        padding-bottom: 5px;\n      }\n      .text {\n        font-size: 25px;\n      }\n    }\n  }\n}\n\n.footer_rights {\n  padding: 33px 90px 50px 90px;\n  display: flex;\n  justify-content: space-between;\n  .ffy_conditions {\n    display: flex;\n    justify-content: space-between;\n    width: 70%;\n    font-size: 16px;\n    font-weight: 700;\n  }\n  @include mobile {\n    font-size: 30px;\n    align-items: center;\n    flex-direction: column;\n    .ffy_conditions {\n      padding: 20px 0;\n      font-size: 30px;\n      width: 100%;\n    }\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
