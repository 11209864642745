// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usecase__comments_container {
  padding: 100px 120px 50px 120px;
  background-color: white;
}
.usecase__comments_container .usecase_comments_content {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  padding: 30px 20px 0 20px;
}
.usecase__comments_container .usecase_comments_content .avatar {
  width: 100px;
  height: 100px;
  padding: 20px;
}
.usecase__comments_container .usecase_comments_content .avatar img {
  width: 90px;
  object-fit: cover;
}
.usecase__comments_container .usecase_comments_content .usecase_comments {
  flex: 1 1;
  padding: 10px;
}
.usecase__comments_container .usecase_comments_content .usecase_comments p {
  width: 750px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  color: #23272a;
  font-weight: 600;
  font-family: Work Sans;
}
@media (max-width: 499px) {
  .usecase__comments_container {
    padding: 100px 30px;
  }
  .usecase__comments_container .usecase_comments_content {
    flex-direction: column;
  }
  .usecase__comments_container .usecase_comments_content .usecase_comments {
    width: 80%;
  }
  .usecase__comments_container .usecase_comments_content .usecase_comments p {
    width: 100%;
    font-size: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/useCases/home/components/Comments/comments.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AACA;EACE,+BAAA;EACA,uBAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;EACA,yBAAA;AACJ;AAAI;EACE,YAAA;EACA,aAAA;EACA,aAAA;AAEN;AADM;EACE,WAAA;EACA,iBAAA;AAGR;AAAI;EACE,SAAA;EACA,aAAA;AAEN;AADM;EACE,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,sBAAA;AAGR;AC7BE;EDFF;IAiCI,mBAAA;EAEF;EADE;IACE,sBAAA;EAGJ;EAFI;IACE,UAAA;EAIN;EAHM;IACE,WAAA;IACA,eAAA;EAKR;AACF","sourcesContent":["@import \"theme/theme.scss\";\n.usecase__comments_container {\n  padding: 100px 120px 50px 120px;\n  background-color: white;\n  .usecase_comments_content {\n    display: flex;\n    align-items: center;\n    gap: 50px;\n    justify-content: space-between;\n    padding: 30px 20px 0 20px;\n    .avatar {\n      width: 100px;\n      height: 100px;\n      padding: 20px;\n      img {\n        width: 90px;\n        object-fit: cover;\n      }\n    }\n    .usecase_comments {\n      flex: 1;\n      padding: 10px;\n      p {\n        width: 750px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        font-size: 24px;\n        color: #23272a;\n        font-weight: 600;\n        font-family: Work Sans;\n      }\n    }\n  }\n  @include mobile {\n    padding: 100px 30px;\n    .usecase_comments_content {\n      flex-direction: column;\n      .usecase_comments {\n        width: 80%;\n        p {\n          width: 100%;\n          font-size: 30px;\n        }\n      }\n    }\n\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
