// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_companies_bloc {
  padding: 50px 250px 0 250px;
}
@media (max-width: 499px) {
  .title_companies_bloc {
    padding: 50px 100px;
  }
  .title_companies_bloc h2 {
    text-align: center;
  }
}
.title_companies_bloc h2 {
  color: #23272a;
  font-size: 40px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/PartnerCompanies/partnercompanies.scss","webpack://./src/theme/theme.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;AADF;ACCE;EDDF;IAGI,mBAAA;EACF;EAAE;IACE,kBAAA;EAEJ;AACF;AAAE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;AAEJ","sourcesContent":["@import 'theme/theme.scss';\n\n.title_companies_bloc {\n  padding: 50px 250px 0 250px;\n  @include mobile {\n    padding: 50px 100px;\n    h2 {\n      text-align: center;\n    }\n  }\n  h2 {\n    color: #23272a;\n    font-size: 40px;\n    font-weight: 700;\n  }\n}\n","\n\n@mixin mobile {\n  @media (max-width: 499px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
